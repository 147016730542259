<template>
  <div
    class="row position-relative"
    :class="
      RoutePage == 'QuizFinal'
        ? 'm-0 p-0 w-100 big-div-quiz'
        : ' w-85 big-div'
    "
    style="z-index: 100"
    :style="getPageDesign()">
    <!-- BackGroundDiv start-->
    <script2 v-if="pageDesign.pageStyles.jsCode">
      {{ pageDesign.pageStyles.jsCode }}
    </script2>
    <div
      class="bg lazyload"
      :data-bg="MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))"
      v-if="getImageAlign == 'no'"
      :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
      :style="
        getbackgroundImage(
          IsImageAdded,
          pageDesign.imageBlock,
          getImageAlign,
          isDesktopView
        ) + getBgDivStyles()
      "></div>
    <!-- BackGroundDiv end-->
    <div v-if="showAlert" class="AlertContainer">
      <div
        class="alert text-center fade show text-white alert-text"
        role="alert"
        style="background: #f27367; width: fit-content">
        <span
          ><i class="fa fa-exclamation-triangle mr-2" aria-hidden="true"></i>
          {{ showAlertText }}</span
        >
      </div>
    </div>
    <!-- Image Align Left -->
    <div
      class="px-0"
      v-show="showLeftImage"
      :class="[
        {
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division col-6': isDesktopView,
        },
      ]"
      :style="
        calculateImageWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive
        )
      ">
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView)">
          <LazyImage
            :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
            :style="
              getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style,
                IsImageVariableWidthActive,
                pageDesign.imageBlock
              ) +
              getImageRotation(pageDesign.imageBlock.style) +
              getImageOpacity(pageDesign.imageBlock.style)
            "
            :class="[
              `pic-${getSelectedPage.order}`,
              getImageSrc(pageDesign.imageBlock, isDesktopView) ==
              '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ]"
            :src="MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))"
            alt />
        </div>
      </div>
    </div>

    <!-- Main Section -->
    <div
      :class="[!IsImageAdded ? 'col-12' : imageAddedLayout]"
      :style="
        getMainPaddingCss(pageDesign.pageStyles, isDesktopView) +
        getScrollCSS(
          pageDesign.pageStyles,
          isDesktopView,
          pageDesign.imageBlock.style,
          pageDesign.pageType
        ) +
        calculateContentWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive,
          pageDesign.pageStyles
        )
      ">
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          height: 100%;
        ">
        <div style="overflow: hidden scroll" class="h-100">
          <div>
            <PreviewProgressBar :order="CurrentPage.order" />
          </div>
          <div
            v-for="(pro, ind) in pageDesign.blocksArray"
            :key="'preview' + ind"
            :class="['item-' + ind, pro.type === 'tooltip' ? 'tooltip-zIndex' : '']"
            :data-aos="animationType"
              :data-aos-duration="getAnimationSpeedinMillis"
              :data-aos-delay="AOSAnimationDelay(ind)"
              :data-aos-anchor="`.item-${ind == 0 ? ind : ind - 1}`"
            >
            <div
              id="preview"
              :style="getHeightWidth(pro.style)"
              class="content"
              style="position: relative">
              <div
                v-if="pro.type == 'text'"
                :style="
                  getColorCss(pro.style) +
                  getPaddingCss(pro.style) +
                  getMarginCss(pro.style, isDesktopView) +
                  getColorWithOpacity(pro.style) +
                  getTextRotate(pro.style) +
                  getLineHeight(pro.style) +
                  getTextAlign(pro.style) +
                  getFontStyle(pro.style,isDesktopView)
                "
                v-html="getTextValue(pro.content)"></div>
              <div
                v-if="pro.type == 'html'"
                :style="
                  getMarginCss(pro.style, isDesktopView) +
                  getColorWithOpacity(pro.style) +
                  getTextRotate(pro.style) +
                  getTextAlign(pro.style)
                ">
                <HtmlElement :pro="pro" :isDesktopView="isDesktopView" />
              </div>

              <!-- </VueDragResize> -->
              <div v-if="pro.type == 'option'">
                <div v-if="checkToalettsitsarClient">
                  <Toalettsitsar
                    :pro="pro"
                    @selectedOptions="
                      (selected) => {
                        selectedOptions = selected;
                      }
                    "></Toalettsitsar>
                </div>
                 
                <div v-if="checkFrieghtQuotClient" class="" :style="getMarginCss(pro.style, isDesktopView)+ getPaddingCss(pro.style)">
                
                  <FreightQuot :pro="pro" 
                  :currentQuizId = CurrentPage.id
                  :FrieghtQuotClient = FrieghtQuotClient
             
                    @selectedOptions="handleFreightQuotSelectOptions"></FreightQuot>
                </div>
                <div v-if="!checkToalettsitsarClient && !checkFrieghtQuotClient">
                  <!-- Checkbox Options -->

                  <div
                    :style="
                      getMarginCss(pro.style, isDesktopView) +
                      getTextAlign(pro.style)
                    ">
                    <div class="" v-if="isIkeaClientTagQuestion">
                      <p class="m-0 p-0" style="text-align: right">
                        {{ IkeaClientCounterValue }}
                      </p>
                    </div>
                    <div v-if="getCurrentSelectedOptionType === 'text'">
                      <div
                        v-if="pro.style.textAlign != 'end'"
                        :class="
                          getOptionAlignment == 'vertical'
                            ? 'd-flex flex-column'
                            : 'd-flex  flex-wrap'
                        ">
                        <div
                          class="form-check checkboxDiv"
                          v-for="(checkbox, index) in getOptionsArr"
                          :key="index"
                          :class="
                            getOptionAlignment == 'horizontal'
                              ? 'mr-2'
                              : 'mb-1'
                          "
                          :style="selectedCheckboxColor(pro.style)">
                          <div
                            class="d-flex align-items-center w-100"
                            :style="`justify-content:${pro.style.textAlign};`">
                            <input
                              class="form-check-input m-0 mr-2"
                              type="checkbox"
                              :id="index"
                              :style="{
                                height: pro.style.fontSize + 'px',
                                width: pro.style.fontSize + 'px',
                                position: 'relative',
                              }"
                              :value="checkbox"
                              v-model="selectedOptions"
                              :disabled="limitSelectionCheckbox(checkbox)" />
                            <label
                              class="form-check-label m-0"
                              :style="
                                getColorCss(pro.style) +
                                getColorWithOpacity(pro.style) +
                                getFontStyle(pro.style)
                              "
                              :for="index">
                              <span v-html="checkbox.value"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        v-else
                        :class="
                          getOptionAlignment == 'vertical'
                            ? 'd-flex flex-column'
                            : 'd-flex  flex-wrap'
                        ">
                        <div
                          class="form-check checkboxDiv"
                          v-for="(checkbox, index) in getOptionsArr"
                          :key="index"
                          :class="
                            getOptionAlignment == 'horizontal'
                              ? 'mr-2'
                              : 'mb-1'
                          "
                          :style="selectedCheckboxColor(pro.style)">
                          <div
                            class="d-flex align-items-center w-100"
                            :style="`justify-content:${pro.style.textAlign};`">
                            <label
                              class="form-check-label m-0 mr-2"
                              :style="
                                getColorCss(pro.style) +
                                getColorWithOpacity(pro.style) +
                                getFontStyle(pro.style)
                              "
                              :for="index">
                              <span v-html="checkbox.value"></span>
                            </label>
                            <input
                              class="form-check-input m-0"
                              type="checkbox"
                              :style="{
                                height: pro.style.fontSize + 'px',
                                width: pro.style.fontSize + 'px',
                                position: 'relative',
                              }"
                              :id="index"
                              :value="checkbox"
                              v-model="selectedOptions"
                              :disabled="limitSelectionCheckbox(checkbox)" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="getCurrentSelectedOptionType === 'image'">
                      <div class="row">
                        <div
                          v-for="(checkbox, index) in getOptionsArr"
                          :key="index"
                          class="d-flex flex-column"
                          :style="
                            getDivAlignmentStyle(pro.style.textAlign) +
                            getSpaceBetweenRow(pro.style)
                          "
                          :class="
                            isDesktopView
                              ? `col-${12 / pro.OptionsPerRow}`
                              : `col-${12 / pro.OptionsPerMobile}`
                          ">


<div
                                :style="
                                  getImageHeightWidth(
                                    pro.style,
                                    isDesktopView
                                  ) +
                                  `${
                                    IsSelectedImageExist(checkbox)
                                      ? `border:2px solid ${
                                          pro.style.selectedImageIconColor ||
                                          '#000000'
                                        };padding:2px;`
                                      : null
                                  }`
                                "
                                class="ImageInputBlock position-relative"
                                @click="checkSelectedImage(checkbox)"
                              >
                                <LazyImage
                                  :src="
                                    ImageSrc(checkbox.image) ||
                                    `/images/image_placeholder.png`
                                  "
                                  alt=""
                                  :key="
                                    ImageSrc(checkbox.image) ||
                                    '/images/image_placeholder.png' + index
                                  "
                                  style="
                                    cursor: pointer;
                                    height: 100%;
                                    width: 100%;
                                  "
                                  :style="getBorderRadius(pro.style)"
                                />
                              </div>


                          <div>
                            <p
                              class="m-0 p-0 mt-2"
                              :style="
                                getColorCss(pro.style) +
                                getColorWithOpacity(pro.style) +
                                getFontStyle(pro.style)
                              "
                              v-html="checkbox.value" 
                              >
                             
                            </p>
                          </div>
                          <div
                            class="counter-parent mx-2 pt-2"
                            v-if="
                              isIkeaClientCounterQuestion &&
                              checkSelectedOptionForCounter(checkbox)
                            ">
                            <div
                              class="d-flex justify-content-center align-items-center">
                              <button
                                @click="
                                  incrementCounter(checkbox.id, 'decrement')
                                "
                                :style="[{ backgroundColor: '#CDE4E3' }]"
                                style="
                                  width: 40px !important;
                                  height: 40px !important;
                                  border-radius: 50% !important;
                                  max-width: 40px !important;
                                  max-height: 40px !important;
                                  min-width: 40px !important;
                                  min-height: 40px !important;
                                  border: none;
                                ">
                                -
                              </button>
                              <!-- <input type="text" style="width: 30px; border:none;"> -->
                              <span style="width: 30px; border: none">{{
                                currentCounterValue(checkbox.id)
                              }}</span>
                              <button
                                @click="
                                  incrementCounter(checkbox.id, 'increment')
                                "
                                :style="[{ backgroundColor: '#CDE4E3' }]"
                                style="
                                  width: 40px !important;
                                  height: 40px !important;
                                  border-radius: 50% !important;
                                  max-width: 40px !important;
                                  max-height: 40px !important;
                                  min-width: 40px !important;
                                  min-height: 40px !important;
                                  border: none;
                                ">
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="getCurrentSelectedOptionType === 'tab'">
                      <div v-if="getOptionAlignment == 'vertical'">
                        <ul
                        :style="
                               `max-width: ${isDesktopView ?  pro.style.defaultTab.maxTabWidthDesktop || 500 : pro.style.defaultTab.maxTabWidthMobile || 500 }px;
                                list-style: none;
                                display: inline-block;`
                              "
                          class="m-0 p-0">
                          <li
                            v-for="(checkbox, index) in getOptionsArr"
                            :key="index"
                            class="mb-2"
                            :class="isDesktopView ? 'Tab' : ''"
                            :style="`min-width:${ isDesktopView ?  pro.style.defaultTab.minTabWidthDesktop || 200 : pro.style.defaultTab.minTabWidthMobile || 200 }px; max-width: ${isDesktopView ?  pro.style.defaultTab.maxTabWidthDesktop || 500 : pro.style.defaultTab.maxTabWidthMobile || 500 }px;`">
                            <div>
                              <button
                                @click="updateSelectedAnswer(checkbox)"
                                class="btn tab-btn border w-100"
                                :style="
                                  isSelectedOptionExist(checkbox)
                                    ? getSelectedTabStyles(
                                        pro.style.selectedTab,
                                        pro.style
                                      )
                                    : getDefaultTabStyles(
                                        pro.style.defaultTab,
                                        pro.style
                                      )
                                ">
                                <span v-html="checkbox.value"></span>
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div v-else class="">
                        <div
                          class="m-0 p-0 row"
                          style="display: flex; flex-wrap: wrap"
                          :style="`height:100%;max-width: 100%; justify-content:${pro.style.textAlign};`">
                          <div
                            v-for="(checkbox, index) in getOptionsArr"
                            :key="index"
                            style="flex-grow: 1"
                            class="mb-2"
                            :class="[
                              isDesktopView ? 'Tab' : '',
                              isDesktopView
                                ? pro.optionSetting.horizontalOptionsPerRow
                                  ? `col-${
                                      12 /
                                      pro.optionSetting.horizontalOptionsPerRow
                                    }`
                                  : 'col-6 col-sm-6 col-md-4'
                                : pro.optionSetting
                                    .horizontalOptionsPerRowMobile
                                ? `col-${
                                    12 /
                                    pro.optionSetting
                                      .horizontalOptionsPerRowMobile
                                  }`
                                : 'col-6 col-sm-6 col-md-4',
                            ]">
                            <div style="height: 100% !important">
                              <button
                                @click="updateSelectedAnswer(checkbox)"
                                class="btn tab-btn border w-100"
                                style="height: 100% !important"
                                :style="
                                  isSelectedOptionExist(checkbox)
                                    ? getSelectedTabStyles(
                                        pro.style.selectedTab,
                                        pro.style
                                      )
                                    : getDefaultTabStyles(
                                        pro.style.defaultTab,
                                        pro.style
                                      )
                                ">
                                <span v-html="checkbox.value"></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="pro.optionSetting.optionType === 'dropdown'">
                      <!--  Dropdown Start -->
                      <div class="select-div">
                        <multiselect
                          v-model="selectedOptions"
                          :options="getOptionsArr"
                          :multiple="true"
                          class="multiSelect"
                          :style="
                            getColorCss(pro.style) +
                            getColorWithOpacity(pro.style) +
                            getFontStyle(pro.style) +
                            QuestionPageStyle(pro.style)
                          "
                          :min="getMultiMin"
                          :max="getMultiMax"
                          :close-on-select="false"
                          :searchable="false"
                          label="value"
                          track-by="value">
                          <template slot="maxElements"><span></span></template>
                        </multiselect>
                        <!-- :disabled="limitSelectionCheckbox()" -->
                      </div>
                      <!--  Dropdown End -->
                    </div>
                  </div>
                </div>
              </div>

              <div
v-if="pro.type == 'imageComp'"

>
<InlineImageComp :pro="pro" :isDesktopView="isDesktopView" />
</div>
              <div
                v-if="pro.type == 'tooltip'"
                :style="`display:flex;justify-content: ${pro.style.position} `">
                <button
                  :style="
                    getButtonCss(pro.style, isDesktopView) +
                    getCircularBtn(pro.isBtnRounded)
                  "
                  @mouseenter="pro.isTooltipVisible = true"
                  @mouseleave="pro.isTooltipVisible = false">
                  <span v-if="pro.useIcon">
                    <BIcon :icon="pro.selectedIcon" />
                  </span>

                  <span
                    v-else
                    :style="{
                      opacity: `${pro.style.textOpacity}%`,
                      fontSize: `${pro.style.fontSize}px`,
                    }">
                    {{ pro.text }}
                  </span>
                </button>
                <ToolTipComp
                  v-if="pro.isTooltipVisible"
                  :tooltipData="pro"
                  :isDesktopView="isDesktopView" />
              </div>
            </div>
          </div>
        </div>

        <!-- Multiple Ans Back Button -->
        <div>
          <div
            :style="getHeightWidth(StaticButtonElement.style)"
            class="content">
            <div class="w-100">
              <div
                class="d-flex"
                :style="
                  getBackNextButtonPosition(
                    StaticButtonElement,
                    CurrentPage.value
                  )
                ">
                <button
                  style="z-index: 1000"
                  :style="
                    getCommonButtonCSS(
                      StaticButtonElement.style,
                      isDesktopView
                    ) +
                    getBackNextButtonCss(
                      StaticButtonElement.content.backButton.style,isDesktopView
                    )
                  "
                  @click="backPage"
                  v-if="!StaticButtonElement.content.backButton.hideBackButton"  class="ButtonHover">
                  <span
                    :style="{
                      opacity: `${StaticButtonElement.content.backButton.style.textOpacity}%`,
                      fontSize: `${StaticButtonElement.content.backButton.style.fontSize}px`,
                    }"
                    >{{ StaticButtonElement.content.backButton.text }}</span
                  >
                </button>
                <button
                v-if="showNextBtn"
                 :disabled="isLoadingQuestion"
                  style="z-index: 1000"
                  :style="
                    getCommonButtonCSS(
                      StaticButtonElement.style,
                      isDesktopView
                    ) +
                    getBackNextButtonCss(
                      StaticButtonElement.content.nextButton.style,isDesktopView
                    )
                  "
                  @click="nextPage"  class="ButtonHover">
                   <b-spinner class="mx-1" v-if="isLoadingQuestion" small label="Small Spinner"></b-spinner>
                  <span
                    :style="{
                      opacity: `${StaticButtonElement.content.nextButton.style.textOpacity}%`,
                      fontSize: `${StaticButtonElement.content.nextButton.style.fontSize}px`,
                    }"
                    >{{ StaticButtonElement.content.nextButton.text }}</span
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Image Align Right -->
    <div
      class="px-0"
      v-show="showRightImage"
      :class="[
        {
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division col-6': isDesktopView,
        },
      ]"
      :style="
        calculateImageWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive
        )
      ">
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView)">
          <LazyImage
            :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
            :style="
              getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style,
                IsImageVariableWidthActive,
                pageDesign.imageBlock
              ) +
              getImageRotation(pageDesign.imageBlock.style) +
              getImageOpacity(pageDesign.imageBlock.style)
            "
            :class="[
              `pic-${getSelectedPage.order}`,
              getImageSrc(pageDesign.imageBlock, isDesktopView) ==
              '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ]"
            :src="MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))"
            alt />
        </div>
      </div>
    </div>

    <!-- Show Logo Footer -->
  </div>
</template>
<script>

import { nextJumpLogicPage } from "../../composibles/singleStepPreviewApi";
import { getFormatedUTCTimeStamp } from "../../composibles/getUTCTimeStamp";
import Vue from "vue";
import VS2 from "vue-script2";
import axios from "axios";
Vue.use(VS2);
import cssFunctionMixin from "../customize/mixin/cssFunction.js";
import Multiselect from "vue-multiselect";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  props: [
  "isTest",
    "isDesktopView",
    "animationType",
    "RoutePage",
    "getIsAnimationEnabled",
    "getAnimationSpeedinMillis",
    "apiToken"
  ],

  mixins: [cssFunctionMixin],
  components: {
    Multiselect,
    HtmlElement: () =>
      import(/* webpackChunkName: "HtmlElement" */ "./HtmlElement.vue"),
    ToolTipComp: () =>
      import(/* webpackChunkName: "ToolTipComp" */ "./ToolTipComp.vue"),
    PreviewProgressBar: () =>
      import(
        /* webpackChunkName: "PreviewProgressBar" */ "./PreviewProgressBar.vue"
      ),
    Toalettsitsar: () =>
      import(
        /* webpackChunkName: "Toalettsitsar" */ "./specialClients/Toalettsitsar.vue"
      ),
      FreightQuot: () =>
      import(
        /* webpackChunkName: "Toalettsitsar" */ "./specialClients/FreightQuot.vue"
      ),
      InlineImageComp: () =>
import(
/* webpackChunkName: "InlineImageComp" */ "./components/InlineImageComp.vue"
),
  },
  data() {
    return {
      activeSideBar: "pageStyle",
      pageDesign: {},
      count: 0,
      selectedOptions: [],
      showAlert: false,
      showAlertText: "",
      email: "",
      name: "",
      phone: "",
      date: "",
      website: "",
      organisation: "",
      address_1: "",
      address_2: "",
      city: "",
      zip_code: "",
      state: "",
      country: "",
      delayIncrement: 0.2, // The delay between each item
      isNextPageLoading: false,
    };
  },
  methods: {
    handleFreightQuotSelectOptions(selected) {
                        this.selectedOptions = selected;
    },
    AOSAnimationDelay(index) {
      const baseDelay = parseInt(this.getAnimationSpeedinMillis) || 200;
      return this.getIsAnimationEnabled ? (index + 1) * baseDelay : 50;
    },
    currentCounterValue(id) {
      let index = this.selectedOptions.findIndex((option) => option.id == id);
      if (index > -1) return this.selectedOptions[index].counterValue;
    },
    incrementCounter(id, operation) {
      let index = this.selectedOptions.findIndex((option) => option.id == id);
      if (index > -1) {
        if (operation === "increment") {
          if (this.currentCounterValue(id) < 4) {
            this.selectedOptions[index].counterValue += 1;
          } else {
            this.selectedOptions[index].counterValue = 4; // To prevent going beyond the range.
          }
        } else if (operation === "decrement") {
          if (this.currentCounterValue(id) > 1) {
            this.selectedOptions[index].counterValue -= 1;
          } else {
            this.selectedOptions[index].counterValue = 1; // To prevent going below the range.
          }
        }
      }
    },
    checkSelectedOptionForCounter(option) {
      let isSelectedOption = this.selectedOptions.some(
        (selected) => selected.id == option.id
      );
      return isSelectedOption;
    },
    backPage() {
      this.backPageAnimation();
      this.goToBackPreviewPage();
    },
    ...mapMutations([
      "jumpToPreviewPage",
      "PreviewPagesStack",
      "backPageAnimation",
      "setIsLoadingQuestion"
    ]),
    ...mapActions([
      "goToNextPreviewPage",
      "goToBackPreviewPage",
      "updateSelectedOptionsObject",
      "createEndQuizPage",
    ]),
    getPageDesign() {
      let design =
        "text-align:" +
        this.pageDesign.pageStyles.textAlign +
        ";color:" +
        this.pageDesign.pageStyles.color +
        "; background:" +
        this.pageDesign.pageStyles.backgroundColor +
        "; width:" +
        this.pageDesign.pageStyles.width +
        "px; " + this.getQuizPageHeight(this.pageDesign.pageStyles.height,this.RoutePage,this.isTest);

      return design;
    },
    getMultiplePageValues() {
      let getStartedDesignValues = {};
      if (this.getTotalPages && this.getSelectedPage) {
        getStartedDesignValues = this.getSelectedPage.pageDesign;
      }
      return getStartedDesignValues;
    },
    // function to find Logic Array Index relative to selected Options
    findLogicArrIndex(LogicArr, selectedOptions) {

      const parsedOptions = LogicArr.map((obj) => ({matchValue : obj.matchValue , optionsArr:JSON.parse(obj.optionIdArr)}));
      const selectedIds = selectedOptions.map((option) => option.id);
      let index = -1;

      parsedOptions.some((obj, i) => {
    if (obj.matchValue === "any") {
        const hasMatchingId = obj.optionsArr.some(option => selectedIds.includes(option.id));
        if (hasMatchingId) {
            index = i;
            return true;  
        }
    } else if (obj.matchValue === "exact") {
        const allMatch = obj.optionsArr.every(option => selectedIds.includes(option.id)) &&
                         obj.optionsArr.length === selectedIds.length;
        if (allMatch) {
            index = i;
            return true;  
        }
    }

    return false;  
});

      return index;
    },
    async IncrementQuestionAnalyticsCount(selectedOptionObj, mode ) {

      if(this.isTest){
        if (mode == "jumpLogic") return true;

        this.goToNextPreviewPage(this.getSelectedPage.value);
        return 
      }
      let { quiz_analytic_id } = this.getSavedLeadID;

      if (quiz_analytic_id) {
        try {
          this.isNextPageLoading = true;
          this.setIsLoadingQuestion(true)
          let quizAnalyticsPayload = {
            api_token: this.apiToken || this.$route.params.id,
            question_id: this.CurrentPage.id,
            quiz_analytic_id: quiz_analytic_id,
            option_ids:
              selectedOptionObj.selectedOption.length > 0
                ? selectedOptionObj.selectedOption.map((x) => x.id)
                : [],
          };

          await axios.post(`/api/question/analytic/add`, quizAnalyticsPayload);

          if (mode == "jumpLogic") return true;

          this.goToNextPreviewPage(this.getSelectedPage.value);
        } catch (error) {
          if (error) {
            this.$toasted.show("Error occured ", {
              theme: "toasted-primary",
              type: "error",
              position: "bottom-center",
              duration: 2000,
            });
          }
        } finally {
          this.isNextPageLoading = false;
          this.setIsLoadingQuestion(false)
        }
      } else {
        try {
          this.isNextPageLoading = true;
          let data = {
            api_token: this.apiToken || this.$route.params.id,
            quizId: this.getPreviewSettings.quizId,
            value_type: "Started",
            started_at: getFormatedUTCTimeStamp(),
          };

          const response = await axios.post(`/api/updateQuizAnalytic`, data);
          if (response.status == 200) {
            let { quiz_analytic_id } = response.data.data;
            if (quiz_analytic_id) {
              this.$store.commit("assignQuizAnaylticsId", quiz_analytic_id);
              this.IncrementQuestionAnalyticsCount(selectedOptionObj, mode);
            }
          }
        } catch (error) {
          if (error) {
            this.$toasted.show("Error occured ", {
              theme: "toasted-primary",
              type: "error",
              position: "bottom-center",
              duration: 2000,
            });
          }
        } finally {
          this.isNextPageLoading = false;
        }
      }
    },

    async nextPage() {
      if (this.getSkipQuestion && !this.selectedOptions.length) {
        const obj = new Object();

        obj.questionId = this.getSelectedPage.id;
        obj.isMasterQuestion = this.getSelectedPage.isMasterQuestion;
        obj.questionType = this.getSelectedPage.value;
        obj.selectedOption = [];
        await this.updateSelectedOptionsObject(obj);
        // await this.IncrementQuestionAnalyticsCount(obj, "normal" );
        this.goToNextPreviewPage(this.getSelectedPage.value);
      } else {
        if (this.selectedOptions.length > 0) {
          let validObj = this.validateLimitOptions;

          if (validObj.valid) {
            const OptionBlockIndex = this.findOptionsIndex;
            if (OptionBlockIndex) {
              this.pageDesign.blocksArray[OptionBlockIndex].selectedOption =
                this.selectedOptions;
              const order = this.getSelectedPage.order;
              const value = this.getSelectedPage.value;
              const pageDesignValue = this.pageDesign;
              this.$store.commit("setUpdatePreviewPageValues", {
                pageDesignValue,
                order,
                value,
              });
            }

            // If  option is selected
            // if(this.selectedOptions.length == 1){
            if (this.selectedOptions.length) {
              //  If Logic Array exist
              // Custom Logic For Ikea Client
              if (
                this.getEnv?.VUE_APP_FURNITURE_CUSTOM_JUMPLOGIC_QUESTION??[].includes(
                  this.CurrentPage.id
                )
              ) {
                let optionId = [100917, 101502, 101518];

                let isOptionExist = this.selectedOptions.some((obj) =>
                  optionId.includes(obj.id)
                );

                if (isOptionExist) {
                  const obj = this.createSelectedOptionArr();
                  await this.updateSelectedOptionsObject(obj);

                  await this.IncrementQuestionAnalyticsCount(obj, "normal");

                  return true;
                }

                //Custom Logic For Bedroom

                if (this.CurrentPage.id == 22619) {
                  let bOptionId = 101519;
                  let cOptionId = 101520;

                  let isBoptionExist = this.selectedOptions.some(
                    (obj) => obj.id == bOptionId
                  );
                  let isCoptionExist = this.selectedOptions.some(
                    (obj) => obj.id == cOptionId
                  );

                  if (isBoptionExist) {
                    //Movue to 2.2

                    const JumpToPreviewPageIndex =
                      this.getTotalPreviewPages.findIndex((x) => x.id == 23033);

                    const obj = this.createSelectedOptionArr();
                    await this.updateSelectedOptionsObject(obj);
                    const currPageObj = this.getSelectedPage;
                    const type = "push";
                      await this.PreviewPagesStack({ type, currPageObj });
                      if (this.getPreviewSettings.single_step_status) {
                        const value = this.selectedOptions;
                        const index = this.findLogicArrIndex(logicArr, value);
                        let page = JSON.parse(logicArr[index].jumpTo);
                        nextJumpLogicPage({
                          api_token: this.apiToken,
                          page_type: page.value, // stripe , started , lead,result
                          page_id: page.id,
                        });
                      } else {
                        await this.jumpToPreviewPage(JumpToPreviewPageIndex);
                      }

                      
                      // await this.jumpToPreviewPage(JumpToPreviewPageIndex);
                   

                    return;
                  } else {
                    if (isCoptionExist) {
                      // Move to 2.3

                      const JumpToPreviewPageIndex =
                        this.getTotalPreviewPages.findIndex(
                          (x) => x.id == 23034
                        );

                      const obj = this.createSelectedOptionArr();
                      await this.updateSelectedOptionsObject(obj);
                      const currPageObj = this.getSelectedPage;
                      const type = "push";
                          await this.PreviewPagesStack({ type, currPageObj });
                        if (this.getPreviewSettings.single_step_status) {
                          const value = this.selectedOptions;
                          const index = this.findLogicArrIndex(logicArr, value);
                          let page = JSON.parse(logicArr[index].jumpTo);
                          nextJumpLogicPage({
                            api_token: this.apiToken,
                            page_type: page.value, // stripe , started , lead,result
                            page_id: page.id,
                          });
                        } else {
                          await this.jumpToPreviewPage(JumpToPreviewPageIndex);
                        }

                      
                        // await this.jumpToPreviewPage(JumpToPreviewPageIndex);
                      

                      return;
                    }
                  }
                }
              }

              const logicArr = this.getSelectedPage.logic;
              if (logicArr.length) {
                // const value = this.selectedOptions[0]
                const value = this.selectedOptions;
                const index = this.findLogicArrIndex(logicArr, value);
                //  if selected Option has jump Logic
                if (index > -1) {
                  const selectedLogic = logicArr[index];
                  if (selectedLogic.logicType == "jumpTo") {
                    const jumpToObj = JSON.parse(selectedLogic.jumpTo);
                    if (jumpToObj.value == "noResultPage") {
          this.$store.state.selectedOptions = {
            questionAnswer: [],
          };
          jumpToObj.value = "resultPage";
        }
                    const JumpToPreviewPageIndex =
                      this.getTotalPreviewPages.findIndex(
                        (x) =>
                          x.id == jumpToObj.id && x.value == jumpToObj.value
                      );


                    // const selectedOptionObj = this.createSelectedOptionObj(value)
                    // await this.updateSelectedOptionsObject(selectedOptionObj);
                    const obj = this.createSelectedOptionArr();
                    await this.updateSelectedOptionsObject(obj);
                    const currPageObj = this.getSelectedPage;
                    const type = "push";
                   
                       await this.PreviewPagesStack({ type, currPageObj });
                      if (this.getPreviewSettings.single_step_status) {
                        const value = this.selectedOptions;
                        const index = this.findLogicArrIndex(logicArr, value);
                        let page = JSON.parse(logicArr[index].jumpTo);
                        nextJumpLogicPage({
                          api_token: this.apiToken,
                          page_type: page.value, // stripe , started , lead,result
                          page_id: page.id,
                        });
                      } else {
                        await this.jumpToPreviewPage(JumpToPreviewPageIndex);
                      }

                     
                    
                  }
                  if (selectedLogic.logicType == "redirect") {
                    let URL = selectedLogic.jumpMsgOrLink;
                    let pattern = /^((http|https|ftp):\/\/)/;
                    if (URL) {
                      if (!pattern.test(URL)) {
                        URL = "http://" + URL;
                      }
                      window.open(URL);
                    }
                  }
                  if (selectedLogic.logicType == "redirect_self") {
        let URL = selectedLogic.jumpMsgOrLink;
        let pattern = /^((http|https|ftp):\/\/)/;
        if (URL) {
          if (!pattern.test(URL)) {
            URL = "http://" + URL;
          }
          window.location.href = URL;
        }
      }
                  if (selectedLogic.logicType == "endQuiz") {
                    this.createEndQuizPage(selectedLogic.jumpMsgOrLink);
                  }
                } else {
                  const obj = this.createSelectedOptionArr();
                  await this.updateSelectedOptionsObject(obj);

                  await this.IncrementQuestionAnalyticsCount(obj, "normal");
                }
              } else {
                const obj = this.createSelectedOptionArr();
                await this.updateSelectedOptionsObject(obj);

                await this.IncrementQuestionAnalyticsCount(obj, "normal");
              }
            }
            // Perform normal function
            else {
              const obj = this.createSelectedOptionArr();
              await this.updateSelectedOptionsObject(obj);
              await this.IncrementQuestionAnalyticsCount(obj, "normal");
            }
          } else {
            this.showAlertText = validObj.text;
            this.showAlert = true;
            setTimeout(
              function () {
                this.showAlert = false;
              }.bind(this),
              3000
            );
          }
        } else {
          // Show Error Message
          if (this.checkToalettsitsarClient) {
            this.showAlertText = this.translateText(
              "Please Fill All the Fields"
            );
          } else {
            this.showAlertText = this.translateText(
              "Select at least one option."
            );
          }
          this.showAlert = true;
          setTimeout(
            function () {
              this.showAlert = false;
            }.bind(this),
            3000
          );
        }
      }
    },
    createSelectedOptionArr() {
      const obj = new Object();
      obj.questionId = this.getSelectedPage.id;
      obj.isMasterQuestion = this.getSelectedPage.isMasterQuestion;
      obj.questionType = this.getSelectedPage.value;
      obj.selectedOption = this.selectedOptions;
      return obj;
    },
    updateSelectedAnswer(value) {
      const index = this.selectedOptions.findIndex(
        (option) => option.id == value.id
      );
      if (index > -1) {
        this.selectedOptions.splice(index, 1);
      } else {
        let isDisabled = this.limitSelectionCheckbox(value);
        if (isDisabled) {
          return;
        } else {
          this.selectedOptions.push(value);
        }
        //  this.selectedOptions.push(value)
      }
    },
    isSelectedOptionExist(value) {
      if (value) {
        const index = this.selectedOptions.findIndex(
          (option) => option.id == value.id
        );
        if (index > -1) {
          return true;
        } else {
          return false;
        }
      }
    },
    checkSelectedImage(value) {
      const ValueIndex = this.selectedOptions.findIndex(
        (option) => option.id == value.id
      );

      if (ValueIndex > -1) {
        this.selectedOptions.splice(ValueIndex, 1);
      } else {
        let isDisabled = this.limitSelectionCheckbox(value);
        if (isDisabled) {
          return;
        } else {
          this.selectedOptions.push(value);
        }
      }
    },
    IsSelectedImageExist(value) {
      const index = this.selectedOptions.findIndex(
        (option) => option.id == value.id
      );
      if (index > -1) {
        return true;
      } else {
        return false;
      }
    },
    ImageSrc(img) {
      if(!img) return null
      let newImage;
      if (img.startsWith("/http")) {
        newImage = img.substring(1);
      } else {
        newImage = img;
      }
      if (
        newImage.indexOf("http://") === 0 ||
        newImage.indexOf("https://") === 0
      ) {
        return newImage;
      } else {
        const baseURL = this.getEnv?.VUE_APP_API_BASE_URL??'';
        return baseURL + newImage.substring(1);
      }
    },

    limitSelectionCheckbox(checkbox) {
      let index = this.findOptionsIndex;
      let isDisabled;
      let isLimit =
        this.pageDesign.blocksArray[index].limitSelection &&
        this.pageDesign.blocksArray[index].limitSelection.limitSelectionValue;

      if (isLimit) {
        let limitType =
          this.pageDesign.blocksArray[index].limitSelection.limitSelectionType;
        if (limitType == "exact") {
          let limitValue =
            this.pageDesign.blocksArray[index].limitSelection.exactSelection;
          if (this.selectedOptions.length >= limitValue) {
            this.showAlertText = this.translateText(
              "Exceeded maximum selections!"
            );
            this.showAlert = true;
            setTimeout(
              function () {
                this.showAlert = false;
              }.bind(this),
              3000
            );
            isDisabled = true;
          } else {
            isDisabled = false;
          }
        } else {
          let maxValue =
            this.pageDesign.blocksArray[index].limitSelection.maxRangeSelection;
          if (this.selectedOptions.length >= maxValue) {
            this.showAlertText = this.translateText(
              "Exceeded maximum selections!"
            );
            this.showAlert = true;
            setTimeout(
              function () {
                this.showAlert = false;
              }.bind(this),
              3000
            );
            isDisabled = true;
          } else {
            isDisabled = false;
          }
        }
      } else {
        isDisabled = false;
      }

      if (typeof checkbox == "undefined") {
        return isDisabled;
      } else {
        return isDisabled && this.selectedOptions.indexOf(checkbox) === -1;
      }
    },
    UpdateDynamicText() {
      if (this.getSavedLeadID.full_name !== "") {
        this.name = this.getSavedLeadID.full_name;
      }
      if (this.getSavedLeadID.email !== "") {
        this.email = this.getSavedLeadID.email;
      }
      if (this.getSavedLeadID.phone_number !== "") {
        this.phone = this.getSavedLeadID.phone_number;
      }
      if (this.getSavedLeadID.date !== "") {
        this.date = this.getSavedLeadID.date;
      }
      if (this.getSavedLeadID.website !== "") {
        this.website = this.getSavedLeadID.website;
      }
      if (this.getSavedLeadID.organisation !== "") {
        this.organisation = this.getSavedLeadID.organisation;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "address1") &&
        this.getSavedLeadID.address1 !== ""
      ) {
        this.address_1 = this.getSavedLeadID.address1;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "address2") &&
        this.getSavedLeadID.address2 !== ""
      ) {
        this.address_2 = this.getSavedLeadID.address2;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "city") &&
        this.getSavedLeadID.city !== ""
      ) {
        this.city = this.getSavedLeadID.city;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "country") &&
        this.getSavedLeadID.country !== ""
      ) {
        this.country = this.getSavedLeadID.country;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "state") &&
        this.getSavedLeadID.state !== ""
      ) {
        this.state = this.getSavedLeadID.state;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "zip_code") &&
        this.getSavedLeadID.zip_code !== ""
      ) {
        this.zip_code = this.getSavedLeadID.zip_code;
      }
    },
  },
  computed: {
    showNextBtn(){
      let {skip_status} = this.CurrentPage
      if(skip_status) return true
      if(this.selectedOptions.length > 0) return true
      return false
    },
    getOptionAlignment() {
      const {
        useSeperateOptionAlignment,
        mobileTextOptionAlignment,
        textOptionAlignment,
      } = this.pageDesign.blocksArray[this.findOptionsIndex];

      return useSeperateOptionAlignment &&
        !this.isDesktopView &&
        mobileTextOptionAlignment
        ? mobileTextOptionAlignment
        : textOptionAlignment;
    },
      isLoadingQuestion(){
      return this.getIsLoadingQuestion
    },
    checkToalettsitsarClient() {
      if(!this.TOALETTSTSAR_CLIENT)
      return false
      return (
        this.TOALETTSTSAR_CLIENT.question_ids.some(
          (id) => id == this.CurrentPage.id
        ) &&
        this.TOALETTSTSAR_CLIENT.quiz_ids.some(
          (id) => id == this.getPreviewSettings.quizId
        )
      );
    },
    checkFrieghtQuotClient() {
      let FRIEGHTQUOT_CLIENT = this.FrieghtQuotClient
        if(FRIEGHTQUOT_CLIENT.length<=0)
        return false
      return (
        FRIEGHTQUOT_CLIENT.some(
          (quiz) => quiz.questionId == this.CurrentPage.id
        ) &&
        FRIEGHTQUOT_CLIENT.some(
          (quiz) => quiz.quizId == this.getPreviewSettings.quizId
        )
      );
    },
    StaticButtonElement() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "button"
      );
      return this.pageDesign.blocksArray[index];
    },

    getMultiMax() {
      let index = this.findOptionsIndex;
      let maxFinalValue;
      let isLimit =
        this.pageDesign.blocksArray[index].limitSelection &&
        this.pageDesign.blocksArray[index].limitSelection.limitSelectionValue;

      if (isLimit) {
        let limitType =
          this.pageDesign.blocksArray[index].limitSelection.limitSelectionType;
        if (limitType == "exact") {
          let limitValue =
            this.pageDesign.blocksArray[index].limitSelection.exactSelection;
          maxFinalValue = parseInt(limitValue);
        } else {
          let maxValue =
            this.pageDesign.blocksArray[index].limitSelection.maxRangeSelection;
          maxFinalValue = parseInt(maxValue);
        }
      } else {
        maxFinalValue = false;
      }

      return maxFinalValue;
    },
    getMultiMin() {
      let index = this.findOptionsIndex;
      let minFinalValue;
      let isLimit =
        this.pageDesign.blocksArray[index].limitSelection &&
        this.pageDesign.blocksArray[index].limitSelection.limitSelectionValue;

      if (isLimit) {
        let limitType =
          this.pageDesign.blocksArray[index].limitSelection.limitSelectionType;
        if (limitType == "exact") {
          let limitValue =
            this.pageDesign.blocksArray[index].limitSelection.exactSelection;
          minFinalValue = parseInt(limitValue);
        } else {
          let minValue =
            this.pageDesign.blocksArray[index].limitSelection.minRangeSelection;
          minFinalValue = parseInt(minValue);
        }
      } else {
        minFinalValue = false;
      }

      return minFinalValue;
    },
    validateLimitOptions() {
      let index = this.findOptionsIndex;
      let isValid;
      let nonValidText = "";
      let isLimit =
        this.pageDesign.blocksArray[index].limitSelection &&
        this.pageDesign.blocksArray[index].limitSelection.limitSelectionValue;

      if (isLimit) {
        let limitType =
          this.pageDesign.blocksArray[index].limitSelection.limitSelectionType;
        if (limitType == "exact") {
          let limitValue =
            this.pageDesign.blocksArray[index].limitSelection.exactSelection;

          if (this.selectedOptions.length < limitValue) {
            isValid = false;
            nonValidText = "Please select more options.";
          } else {
            isValid = true;
            nonValidText = "";
          }
        } else {
          let minValue =
            this.pageDesign.blocksArray[index].limitSelection.minRangeSelection;

          if (this.selectedOptions.length < minValue) {
            isValid = false;
            nonValidText = "Please select more options.";
          } else {
            isValid = true;
            nonValidText = "";
          }
        }
      } else {
        isValid = true;
        nonValidText = "";
      }

      let obj = {
        valid: isValid,
        text: nonValidText,
      };
      return obj;
    },
    staticButton() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "button"
      );
      return this.pageDesign.blocksArray[index].staticButton;
    },
    ...mapGetters([
      "getIsLoadingQuestion",
      "getSelectedPage",
      "getUploadedImage",
      "getTotalPages",
      "getTotalPreviewPages",
      "getPreviewSettings",
      "getPreviewProgressBar",
      "getSavedLeadID",
      "getAnimationSetting",
      "translateText",
      "getFurnitureClientCounterData",
      "getTrackIkeaClientCounter",
      "getEnvConst",
    ]),
    FrieghtQuotClient() {
      let env = this.getEnv?.FRIEGHTQUOT_CLIENT??[];
      if(!(Array.isArray(env)))
      {
        // eslint-disable-next-line no-useless-escape
        env = env.replace(/([{,])(\s*)([A-Za-z0-9_\-]+?)\s*:/g, '$1"$3":');
        return env?JSON.parse(env):[]
      }
      return env
    },
    TOALETTSTSAR_CLIENT() {
      return this.getEnv?.VUE_APP_TOALETTSTSAR_CLIENT??null;
    },
    getEnv() {
      return this.getEnvConst;
    },
    // "getSkipQuestion"
    ProgressBarValues() {
      return this.getPreviewProgressBar;
    },
    getSkipQuestion() {
      return this.CurrentPage.skip_status;
    },
    CurrentPage() {
      return this.getSelectedPage;
    },
    IsImageAdded() {
      if (
        this.getImageSrc(this.pageDesign.imageBlock, this.isDesktopView) !==
          "" ||
        this.getUploadedImage
      )
        return true;
      return false;
    },
    IsImageVariableWidthActive() {
      return this.IsImageAdded && this.getImageAlign != "no"
        ? true
        : false;
    },
    imageAddedLayout() {
      if (this.getImageAlign == "no") return "col-12";
      if (!this.isDesktopView) return "col-12";
      return "col-12 col-xs-12 col-sm-6 col-md-6  ";
    },
    getImageAlign(){
      if (this.pageDesign.pageStyles.useMobileImageAlign && !this.isDesktopView) {
     return  this.pageDesign.pageStyles.mobileImageAlign ;
    } else {
      return this.pageDesign.pageStyles.imageAlign;
    }
    },
    showLeftImage() {
      return (
        (this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayDesktop &&
          this.getImageAlign == "left") ||
        (!this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayMobile &&
          (this.getImageAlign == "left" ||
            this.getImageAlign == "right"))
      );
    },
    showRightImage() {
      return (
        this.isDesktopView &&
        this.pageDesign.imageBlock.style.displayDesktop &&
        this.getImageAlign == "right"
      );
    },
    findOptionsIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) => block.type == "option"
      );
      return index;
    },
    getCurrentSelectedOptionType() {
      if (this.isDesktopView) {
        return this.pageDesign.blocksArray[this.findOptionsIndex].optionSetting.optionType;
      } else {
        return (
          this.pageDesign.blocksArray[this.findOptionsIndex].optionSetting.mobileOptionsView ??
          this.pageDesign.blocksArray[this.findOptionsIndex].optionSetting.optionType
        );
      }
    },
    getOptionsArr() {
      if (this.findOptionsIndex > -1) {
        if (this.isOptionsShuffled) {
          const arrayCopy = [
            ...this.pageDesign.blocksArray[this.findOptionsIndex].options,
          ];
          for (let i = arrayCopy.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [arrayCopy[i], arrayCopy[j]] = [arrayCopy[j], arrayCopy[i]];
          }

          return arrayCopy;
        } else {
          return this.pageDesign.blocksArray[this.findOptionsIndex].options;
        }
      }
      return [];
    },
    isOptionsShuffled() {
      return Object.prototype.hasOwnProperty.call(
        this.pageDesign.blocksArray[this.findOptionsIndex],
        "shuffleOptions"
      )
        ? this.pageDesign.blocksArray[this.findOptionsIndex].shuffleOptions
        : false;
    },
    isIkeaClientCounterQuestion() {
      const FURNITURE_CLIENT_QUIZID = this.getEnv?.VUE_APP_FURNITURE_CLIENT_QUIZID??null ;
      const CLIENT_QUESTION_NAME= this.getEnv?.VUE_APP_FURNITURE_CLIENT_QUESTION_NAME??null;
      return (
        this.getPreviewSettings.quizId == FURNITURE_CLIENT_QUIZID &&
        this.CurrentPage.name == CLIENT_QUESTION_NAME
          
      );
    },

    isIkeaClientTagQuestion() {
      let tagsQuestions = this.getEnv?.VUE_APP_FURNITURE_CATEGORY_QUESTION??[];
       const FURNITURE_CLIENT_QUIZID = this.getEnv?.VUE_APP_FURNITURE_CLIENT_QUIZID??null 
      
      return this.getPreviewSettings.quizId ==
        FURNITURE_CLIENT_QUIZID &&
        tagsQuestions.some((data) => data.id == this.CurrentPage.id)
        ? true
        : false;
    },
    IkeaClientCounterValue() {
      if (this.isIkeaClientTagQuestion) {
        let currentTagQuestion =
          this.getEnv?.VUE_APP_FURNITURE_CATEGORY_QUESTION??[].find(
            (obj) => obj.id == this.CurrentPage.id
          );

        let tag = currentTagQuestion.type;
        let finalCounterValue =
          this.getFurnitureClientCounterData()[tag].counter;
        let currentCounterValue = this.getTrackIkeaClientCounter[tag].counter;

        return `(${currentCounterValue} / ${finalCounterValue})`;
      }
      return null;
    },
  },
  mounted() {
    this.UpdateDynamicText();

    if (this.isIkeaClientCounterQuestion) {
      let index = this.findOptionsIndex;
      if (index > -1) {
        let options = [...this.pageDesign.blocksArray[index].options];
        this.pageDesign.blocksArray[index].options = options.map((option) => {
          return { ...option, counterValue: 1 };
        });
      }
    }

    if (
      this.getPreviewSettings.quizId ==
      this.getEnv?.VUE_APP_FURNITURE_CLIENT_QUIZID??null
    ) {
      // Add Counter Values To text
      let tagsQuestions = this.getEnv?.VUE_APP_FURNITURE_CATEGORY_QUESTION??[];

      if (tagsQuestions.some((data) => data.id == this.CurrentPage.id)) {
        // Remove Selected Answer

        this.pageDesign.blocksArray[this.findOptionsIndex].selectedOption = [];
      }
    }
  },
  created() {
    this.pageDesign = this.getMultiplePageValues();
    const OptionBlockIndex = this.findOptionsIndex;
    const FURNITURE_CLIENT_QUIZID = this.getEnv?.VUE_APP_FURNITURE_CLIENT_QUIZID??null
    if (
      OptionBlockIndex &&
      this.getPreviewSettings.quizId != FURNITURE_CLIENT_QUIZID
        
    ) {
      this.selectedOptions =
        this.pageDesign.blocksArray[OptionBlockIndex].selectedOption;
    }
  },
  watch: {
    getTotalPages(newVal, oldVal) {
      if (oldVal.length < newVal.length) {
        this.count++;
      }
      this.pageDesign = this.getMultiplePageValues();
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.alert-text {
  padding: 4px 12px !important;
  margin: 0px;
  width: fit-content;
  border-radius: 6px !important;
}
.custom-select-aanswer {
  display: flex;
  border: 1px solid #000000;
  border-radius: 6px;
  height: 36px;
  background: #ededee;
  align-items: center;
}
.custom-select-aanswer img {
  width: 22px;
  height: 22px;
  position: absolute;
  left: 24px;
}
.question-type-select {
  background-color: transparent;
  border: none;
  padding: 4px 39px !important;
  height: 36px;
}
.sidebar {
  max-height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 100vh;
}
.button-group {
  color: #535353;
  outline: #c4c4c4 solid 1px;
}
.button-selected {
  background: #000000 !important;
  color: #ffffff !important;
}
.text-img-btn-selected {
  background: #ffa201 !important;
  color: #ffffff !important;
}
.size-input {
  width: 60px;
  height: 20px;
  outline: #c4c4c4 solid 1px;
  border-radius: 4px;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e0dee2 !important;
  border-color: #ced3d7 !important;
}

.custom-control-input.radio:checked ~ .custom-control-label::before {
  background-color: #0397cc !important;
  color: #0397cc !important;
  border-color: #0397cc !important;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffa201 !important;
  border-color: #ced3d7 !important;
}

.big-div-quiz {
  padding: 0px;
}

/* @media only screen and (max-width: 767px) {
  .big-div-quiz {
    width: 100% !important;
    padding: 0px 15px;
  }
} */
/* @media only screen and (max-width: 1366px) {
  .big-div-quiz {
    padding: 0px 15px !important;
  }
} */

/* DropdownStyles */
.select-div >>> .multiselect .multiselect__tag {
  background: var(--dropdownHoverColor) !important;
}
.select-div >>> .multiselect .multiselect__tag-icon:after {
  color: #ffffff !important;
}
.select-div >>> .multiselect .multiselect__option--highlight,
.select-div >>> .multiselect .multiselect__option--highlight:after {
  background: var(--dropdownHoverColor) !important;
}

.checkboxDiv input[type="checkbox"] {
  accent-color: var(--selectedCheckboxColor);
  /* height: var(--checkboxHeight)px !important;
width: var(--checkboxWidth)px !important; */
}

.slideRTL {
  /* animation: slide-in var(--animation-speed) forwards;
    -webkit-animation: slide-in var(--animation-speed) forwards; */
  animation-name: slide-in;
  animation-duration: var(--animation-speed);
  animation-timing-function: forwards;
  animation-fill-mode: both;
}

.slideLTR {
  /* animation: slide-out var(--animation-speed) forwards;
    -webkit-animation: slide-out var(--animation-speed) forwards; */
  animation-name: slide-out;
  animation-duration: var(--animation-speed);
  animation-timing-function: forwards;
  animation-fill-mode: both;
}
.tooltip-zIndex{ position:relative;z-index:11111;}
</style>